import React, { useState } from "react";
import useGames from "../hooks/useGames";
import { collection, doc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Table from "../interface/Table";
import { Button, ButtonGroup, Loading, Spacer, Spinner } from "@geist-ui/core";
import NewGameModal from "../game/NewGameModal";

const ExistingGames = () => {
    const navigate = useNavigate();
    const [createGameVisible, setCreateGameVisible] = useState(false);
    const { data: games, refetch: refetchGames } = useGames();

    const onDelete = async (gameId) => {
        const batch = writeBatch(db);
        const objectsToDelete = await getDocs(query(collection(db, "games", gameId, "players")));
        objectsToDelete.forEach(doc => {
            batch.delete(doc.ref);
        });
        batch.delete(doc(db, "games", gameId));

        // Delete related atlas for game
        const atlasRef = collection(db, "atlas");
        const q = query(atlasRef, where("gameId", "==", gameId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((d) => {
            batch.delete(doc(db, "atlas", d.id));
        });

        await batch.commit();

        await refetchGames();
    };

    const onJoin = (id) => {
        navigate(`/teacher-dashboard/${id}`);
    };

    if (!games) {
        return (
            <Loading />
        );
    }

    const gamesToDisplay = games.filter(game => game.gamePhase !== "ended");

    const columns = [
        {
            id: "name",
            label: "name"
        },
        {
            id: "phase",
            label: "phase"
        },
        {
            id: "id",
            label: "actions",
            render: (id) => (
                <ButtonGroup>
                    <Button onClick={() => onJoin(id)}>Join</Button>
                    <Button onClick={() => onDelete(id)}>Delete</Button>
                </ButtonGroup>
            ),
            width: 150
        }
    ];

    return (
        <>
            <Button style={{ float: "right", marginBottom: 10 }} type="success"
                    onClick={() => setCreateGameVisible(true)}>Create game</Button>
            <Table data={gamesToDisplay} columns={columns} emptyText="No games found" />
            {createGameVisible &&
                <NewGameModal visible={createGameVisible} onClose={() => setCreateGameVisible(false)} />}
        </>
    );
};
export default ExistingGames;

import { db } from "./firebase";
import { collection, query, getDocs, orderBy } from "firebase/firestore";

const getRandomCard = (cardsArray) => cardsArray[Math.floor(Math.random() * cardsArray.length)];

const dealCards = async ({ deckId, territoryCardsAmount, legendCardsAmount }) => {
    try {
        console.log(`Dealing cards for deckId: ${deckId}`); // Debug log
        // Fetch cards from Firestore
        const fetchCards = async (cardType) => {
            const cardQuery = query(collection(db, "decks", deckId, cardType), orderBy("name"));
            const querySnapshot = await getDocs(cardQuery);
            const cards = [];
            querySnapshot.forEach((doc) => {
                cards.push({ id: doc.id, ...doc.data() });
            });
            return cards;
        };

        const [territoryCards, legendCards] = await Promise.all([
            fetchCards("territoryCards"),
            fetchCards("legendCards")
        ]);

        // Randomly select cards
        const selectedTerritoryCards = Array.from({ length: territoryCardsAmount }, () => getRandomCard(territoryCards));
        const selectedLegendCards = Array.from({ length: legendCardsAmount }, () => getRandomCard(legendCards));

        return {
            territoryCards: selectedTerritoryCards,
            legendCards: selectedLegendCards
        };
    } catch (error) {
        console.error(`Error in dealCards for deckId: ${deckId}`, error);
        return { territoryCards: [], legendCards: [] };
    }
};

export default dealCards;
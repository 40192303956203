import React from "react";
import "./Card.css";

const Card = ({ card, selected, onClick }) => (
    <div className={`card ${selected ? 'selected' : ''}`} onClick={onClick}>
        <h3>{card.name}</h3>
        {/* <img src={card.image} alt={card.name} /> */}
        {/* other card properties */}
    </div>
);

export default Card;


import React from "react";

const Profile = () => {
    const { currentUser } = useAuth();

    return (
        <div>
            <h1>Profile</h1>
            {currentUser && <p>Email: {currentUser.email}</p>}
        </div>
    );
};

export default Profile;

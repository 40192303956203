import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const useDeck = (deckId) => {
    const [deck, setDeck] = useState();

    const fetchDeck = async () => {
        const [deckDoc, inkCardsDocs, legendCardsDocs, territoryCardsDocs] = await Promise.all([
            getDoc(doc(db, "decks", deckId)),
            getDocs(collection(db, "decks", deckId, "inkCards")),
            getDocs(collection(db, "decks", deckId, "legendCards")),
            getDocs(collection(db, "decks", deckId, "territoryCards"))
        ]);
        setDeck({
            id: deckDoc.id, ...deckDoc.data(),
            inkCards: inkCardsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            legendCards: legendCardsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            territoryCards: territoryCardsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        });
    };

    useEffect(() => {
        if (deckId) {
            fetchDeck();
        }
    }, [deckId]);

    return deck;
};

export default useDeck;
import React from "react";
import useDeck from "../hooks/useDeck";
import Select from "../interface/Select";

const LegendSelect = ({ deckId, value, onChange }) => {
    const deck = useDeck(deckId);

    return (<Select
            placeholder="Select a legend card"
            value={value}
            onChange={onChange}
            options={deck?.legendCards?.map(card => ({ value: card.id, label: card.name }))}
        />);
};
export default LegendSelect;

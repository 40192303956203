import React, { useState } from "react";
import { signInWithEmailAndPassword } from "./firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Display, Input, Spacer } from "@geist-ui/core";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setError("");
        setLoading(true);
        try {
            await signInWithEmailAndPassword(email, password);
            navigate("/dashboard");
        } catch (error) {
            let errorMessage;
            switch (error.code) {
                case "auth/invalid-email":
                    errorMessage = "Invalid email format.";
                    break;
                case "auth/user-disabled":
                    errorMessage = "This user has been disabled.";
                    break;
                case "auth/user-not-found":
                    errorMessage = "No user found with this email.";
                    break;
                case "auth/wrong-password":
                    errorMessage = "Incorrect password.";
                    break;
                default:
                    errorMessage = "Failed to log in";
                    break;
            }
            setError(errorMessage);
            console.error("Login error:", error);
        }
        setLoading(false);
    };


    return (
        <Display style={{ textAlign: "center" }} onKeyPress={e => e.code === "Enter" && handleSubmit()}>
            {error && <p>{error}</p>}
            <h1>Login</h1>
            <Input htmlType="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"
                   required />
            <Spacer y={0.5} />
            <Input htmlType="password" value={password} onChange={(e) => setPassword(e.target.value)}
                   placeholder="Password" required />
            <Spacer y={0.5} />
            <Button onClick={handleSubmit} disabled={loading}>Login</Button>
            <Spacer y={0.5} />
            <Link to="/reset-password">Forgot Password?</Link>
        </Display>
    );
};

export default Login;

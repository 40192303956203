import React, { useEffect, useRef, useState } from "react";
import { db } from "./firebase";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import PlayerLinks from "./game/PlayerLinks";
import { useNavigate, useParams } from "react-router-dom";
import useGame from "./hooks/useGame";
import GamePhase from "./game/GamePhase";
import useDeck from "./hooks/useDeck";
import dealCards from "./dealCards";
import VoteResults from "./game/VoteResults";
import { calculateVoteResults } from "./game/utils";
import useAtlas from "./hooks/useAtlas";
import { Button, ButtonGroup, Card, Input, Spacer } from "@geist-ui/core";
import Breadcrumbs from "./interface/Breadcrumbs";

function TeacherDashboard() {
    const { gameId } = useParams();
    const game = useGame(gameId);
    const deck = useDeck(game?.deckId);
    const { atlas } = useAtlas(gameId);
    const [initialTime, setInitialTime] = useState(null); // Represents minutes
    const [remainingTime, setRemainingTime] = useState(null); // Represents seconds
    const intervalRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (game?.initialTime && !initialTime) {
            setInitialTime(game.initialTime);
        }

        if (game?.roundStartedAt) {
            const secondsElapsed = Math.floor((new Date().getTime() - game.roundStartedAt.toDate().getTime()) / 1000);
            setRemainingTime((game.initialTime * 60) - secondsElapsed);
        }
    }, [game]);

    const startSelectionPhase = async () => {
        const batch = writeBatch(db);
        for (const player of game.players) {
            const {
                territoryCards: dealtTerritoryCards, legendCards: dealtLegendCards
            } = await dealCards({ deckId: game.deckId, territoryCardsAmount: 3, legendCardsAmount: 2 });

            batch.update(doc(db, "games", gameId, "players", player.id), {
                territoryCards: dealtTerritoryCards, legendCards: dealtLegendCards
            });
        }

        batch.update(doc(db, "games", gameId), { phase: GamePhase.CARD_SELECTION });

        await batch.commit();
    };

    // Handles game state changes
    useEffect(() => {
        if (game?.players?.every(p => p.ready) && game.phase === GamePhase.PREPARATION) {
            startSelectionPhase();
            return;
        }

        if (atlas?.possibilities?.every(p => p.unlocked)) {
            updateDoc(doc(db, "games", gameId), { phase: GamePhase.ENDED });
            return;
        }

        if (game?.players?.every(p => p.votes) && game.phase === GamePhase.VOTING) {
            updateDoc(doc(db, "games", gameId), { phase: GamePhase.VOTE_RESULTS });
            const correctVotes = calculateVoteResults(game);
            const votesToSave = [];
            Object.keys(correctVotes).forEach(playerId => {
                const shouldPushVote = correctVotes[playerId] >= game.minimumPlayersForUnlock;
                if (shouldPushVote) {
                    const player = game.players.find(p => p.id === playerId);
                    votesToSave.push({
                        playerId,
                        legendCardId: player.selectedLegend,
                        territoryCardId: player.selectedTerritory
                    });
                }
            });

            let possibilities = [...atlas.possibilities];
            votesToSave.forEach(async vote => {
                possibilities = possibilities.map(possibility => {
                    if (possibility.legendCardId === vote.legendCardId && possibility.territoryCardId === vote.territoryCardId) {
                        return { ...possibility, unlocked: true };
                    }
                    return possibility;
                });
            });

            // Need to update the entire collection because Firestore does not support updating a specific array element
            updateDoc(doc(db, "atlas", atlas.id), { possibilities });
        }
    }, [game]);

    const drawInkCard = async () => {
        if (deck?.inkCards.length > 0) {
            const randomIndex = Math.floor(Math.random() * deck.inkCards.length);
            const selectedCard = deck.inkCards[randomIndex];
            const gameDoc = doc(db, "games", gameId);
            await updateDoc(gameDoc, { selectedInkCard: selectedCard });
        }
    };

    const handleTimerChange = async (event) => {
        if ([GamePhase.CARD_SELECTION, GamePhase.PREPARATION].includes(game.phase)) {
            setInitialTime(event.target.value);
            setRemainingTime(event.target.value * 60);
            await updateDoc(doc(db, "games", gameId), { initialTime: event.target.value });
        }
    };

    const startRound = async () => {
        if (!game.players.every(p => p.selectedTerritory && p.selectedLegend)) {
            alert("Not all players picked their cards yet");
            return;
        }

        if (initialTime > 0 && game.selectedInkCard) {
            const gameDoc = doc(db, "games", gameId);
            await updateDoc(gameDoc, { phase: GamePhase.ROUND_TIMER, roundStartedAt: new Date() });
            setRemainingTime(initialTime * 60);
        } else {
            alert("Please set the timer and draw an ink card before starting the round.");
        }
    };

    const forceRoundEnd = async () => {
        await updateDoc(doc(db, "games", gameId), { forceRoundStop: true });
        setRemainingTime(0);
    };

    const startNewRound = async () => {
        const batch = writeBatch(db);
        for (const player of game.players) {
            const {
                territoryCards, legendCards
            } = await dealCards({ deckId: game.deckId, territoryCardsAmount: 1, legendCardsAmount: 1 });
            const usedTerritoryCardIndex = player.territoryCards.findIndex(c => c.id === player.selectedTerritory);
            const usedLegendCardIndex = player.legendCards.findIndex(c => c.id === player.selectedLegend);
            const newTerritoryCards = player.territoryCards.filter((_, i) => i !== usedTerritoryCardIndex);
            newTerritoryCards.push(...territoryCards);
            const newLegendCards = player.legendCards.filter((_, i) => i !== usedLegendCardIndex);
            newLegendCards.push(...legendCards);

            batch.update(doc(db, "games", gameId, "players", player.id), {
                selectedTerritory: null,
                selectedLegend: null,
                votes: null,
                legendCards: newLegendCards,
                territoryCards: newTerritoryCards
            });
        }
        batch.update(doc(db, "games", gameId), { phase: GamePhase.CARD_SELECTION, roundStartedAt: null });
        await batch.commit();
    };

    useEffect(() => {
        if (game?.phase === GamePhase.ROUND_TIMER) {
            intervalRef.current = setInterval(async () => {
                setRemainingTime(prevRemainingTime => {
                    if (prevRemainingTime <= 0 && prevRemainingTime !== null) {
                        clearInterval(intervalRef.current);
                        const gameDoc = doc(db, "games", gameId);
                        updateDoc(gameDoc, { phase: GamePhase.VOTING });
                        return prevRemainingTime;
                    }

                    const secondsElapsed = Math.floor((new Date().getTime() - game.roundStartedAt.toDate().getTime()) / 1000);
                    return ((game.initialTime * 60) - secondsElapsed);
                });
            }, 1000);
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [game]);

    if (!game) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Breadcrumbs items={[{ label: "Games" }, { label: `Game ${game.name} (${game.phase})` }]} />
            <Spacer y={0.5} />
            <Card width="100%">
                <h4>Configuration</h4>
                <Input htmlType="number" value={initialTime ?? 0} onChange={handleTimerChange}
                       disabled={game.phase === GamePhase.ROUND_TIMER}>Set timer (in minutes)</Input>
                <Spacer y={0.5} />
                <h4>Ink Card</h4>
                {game.selectedInkCard ? game.selectedInkCard.name : "No ink card selected"}
                <Card.Footer>
                    <ButtonGroup>
                        {game.phase === GamePhase.CARD_SELECTION && <Button onClick={startRound}>Start Game</Button>}
                        {game.phase === GamePhase.VOTE_RESULTS && <Button onClick={startNewRound}>Start new round</Button>}
                        <Button onClick={() => navigate(`/game/${gameId}/atlas`)}>View Atlas</Button>
                        <Button onClick={drawInkCard} disabled={game.phase === GamePhase.ROUND_TIMER}>Draw Ink
                            Card</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
            <Spacer y={0.5} />
            <div>


                {game.phase === GamePhase.ROUND_TIMER && (<>
                    <h2>Round started</h2>
                    <p>Remaining
                        Time: {Math.floor(remainingTime / 60)}:{remainingTime % 60 < 10 ? "0" : ""}{remainingTime % 60} minutes</p>
                    <Button type="warning" onClick={forceRoundEnd}>Force round end</Button>
                </>)}
                <Spacer y={1} />
                {game.phase === GamePhase.VOTE_RESULTS && <VoteResults gameId={gameId} />}
                <Spacer y={1} />
                {game.phase === GamePhase.ENDED && <h2>Game Ended</h2>}
            </div>

            <PlayerLinks game={game} />
        </div>);
}

export default TeacherDashboard;


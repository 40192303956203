import { Table as CoreTable } from "@geist-ui/core";

const Table = ({ data = [], columns = [], emptyText }) => {
    return (
        <CoreTable data={data} emptyText={emptyText}>
            {columns.map(column => (
                <CoreTable.Column key={column.id} prop={column.id} label={column.label} render={column.render} width={column.width} />
            ))}
        </CoreTable>
    );
};

export default Table;
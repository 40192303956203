import Table from "../interface/Table";
import useDecks from "../hooks/useDecks";
import { Button, ButtonGroup } from "@geist-ui/core";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import CreateDeckModal from "./CreateDeckModal";
import { useState } from "react";

const DecksOverview = () => {
    const navigate = useNavigate();
    const { decks, fetchDecks } = useDecks();
    const [createDeckVisible, setCreateDeckVisible] = useState(false);

    const onEdit = (id) => {
        navigate(`/edit-deck/${id}`);
    };

    const onDelete = async (deckId) => {
        try {
            const deckRef = doc(db, "decks", deckId);
            await deleteDoc(deckRef);

            const cardCollections = ["inkCards", "legendCards", "territoryCards"];
            for (const collectionName of cardCollections) {
                const cardsSnapshot = await getDocs(collection(db, "decks", deckId, collectionName));
                cardsSnapshot.forEach(async (card) => {
                    await deleteDoc(doc(db, "decks", deckId, collectionName, card.id));
                });
            }
            await fetchDecks();
        } catch (error) {
            console.error("Error deleting deck:", error);
        }
    };

    const columns = [
        {
            id: "name",
            label: "name"
        },
        {
            id: "id",
            label: "Actions",
            render: (id) => (
                <ButtonGroup>
                    <Button onClick={() => onEdit(id)}>Edit</Button>
                    <Button onClick={() => onDelete(id)}>Delete</Button>
                </ButtonGroup>
            ),
            width: 150
        }
    ];

    return (
        <>
            <Button type="success" onClick={() => setCreateDeckVisible(true)}
                    style={{ float: "right", marginBottom: 10 }}>Create deck</Button>
            <Table data={decks} columns={columns} />
            <CreateDeckModal visible={createDeckVisible} onClose={() => setCreateDeckVisible(false)} />
        </>
    );
};

export default DecksOverview;
import { Breadcrumbs as CoreBreadcrumbs } from "@geist-ui/core";
import { Home } from "@geist-ui/icons";

const Breadcrumbs = ({ items = [] }) => {
    return (
        <CoreBreadcrumbs>
            <CoreBreadcrumbs.Item href="/dashboard"><Home /> Home</CoreBreadcrumbs.Item>
            {items.map((item) => (
                <CoreBreadcrumbs.Item key={item.label} href={item.href} onClick={item.onClick}>{item.label}</CoreBreadcrumbs.Item>))}
        </CoreBreadcrumbs>
    );
};

export default Breadcrumbs;
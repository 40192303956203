import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import GamePhase from "../game/GamePhase";

const useGame = (gameId) => {
    const [game, setGame] = useState();

    // TODO: Caching with react query?
    const getGame = async (id) => {
        const gameDoc = await getDoc(doc(db, "games", id));
        const playersRaw = await getDocs(query(collection(db, "games", id, "players")));
        const players = playersRaw.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGame({ id: gameDoc.id, ...gameDoc.data(), players });
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "games", gameId), (docSnapshot) => {
            setGame(prev => ({ ...prev, ...docSnapshot.data() }));
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "games", gameId, "players"), (docSnapshot) => {
            const players = docSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setGame(prev => ({ ...prev, players }));
        });

        return () => {
            unsubscribe();
        };
    }, []);


    useEffect(() => {
        getGame(gameId);
    }, [gameId]);

    return game;
};

export default useGame;
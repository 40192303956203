import React, { useEffect, useState, useMemo } from "react";
import { db } from "./firebase";
import useGame from "./hooks/useGame";
import TerritorySelect from "./form/TerritorySelect";
import LegendSelect from "./form/LegendSelect";
import { doc, updateDoc } from "firebase/firestore";
import usePlayer from "./hooks/usePlayer";
import { Button, Card } from "@geist-ui/core";
import Table from "./interface/Table";
import SubmitVoteConfirmationModal from "./game/SubmitVoteConfirmationModal";

const Voting = ({ gameId, playerId }) => {
    const game = useGame(gameId);
    const player = usePlayer(gameId, playerId);
    // Votes is an array of objects, each object represents the vote by the active player for playerId
    const [votes, setVotes] = useState(null);
    const [voted, setVoted] = useState(false);
    const [confirmVoteModal, setConfirmVoteModal] = useState(false);

    const votablePlayers = useMemo(() => game?.players.filter(p => p.id !== playerId), [game, playerId]);
    const votedOnEveryone = votes?.every(v => v.territory && v.legend);

    useEffect(() => {
        if (game?.players && votes === null) {
            setVotes(votablePlayers.map(p => ({ playerId: p.id, territory: "", legend: "" })));
        }
    }, [game]);

    useEffect(() => {
        // Player voted for everyone
        if (game && player && player.votes?.length === votablePlayers.length) {
            setVoted(true);
        }
    }, [player, game]);

    const onLegendVoteChange = (index, value) => {
        setVotes(oldVotes => {
            const newVotes = [...oldVotes];
            newVotes[index].legend = value;
            return newVotes;
        });
    };

    const onTerritoryVoteChange = (index, value) => {
        setVotes(oldVotes => {
            const newVotes = [...oldVotes];
            newVotes[index].territory = value;
            return newVotes;
        });
    };

    const submitVote = async () => {
        try {
            await updateDoc(doc(db, "games", gameId, "players", playerId), { votes });
            setVoted(true);
            setConfirmVoteModal(false);
        } catch (error) {
            console.error("Error updating vote:", error);
        }
    };

    if (!game || !votes) {
        return <div>Loading...</div>;
    }

    const columns = [
        {
            id: "name",
            label: "Player Name"
        },
        {
            id: "territory",
            label: "Territory",
            render: (id, player, index) => <TerritorySelect deckId={game.deckId} value={votes[index].territory}
                                                            onChange={v => onTerritoryVoteChange(index, v)} />
        },
        {
            id: "legend",
            label: "Legend",
            render: (id, player, index) => <LegendSelect deckId={game.deckId} value={votes[index].legend}
                                                         onChange={v => onLegendVoteChange(index, v)} />
        }
    ];

    return (
        <>
            <Card width="100%">
                <h2>Voting</h2>
                {voted ? <p>Vote submitted, please wait on the others to finish their vote</p> : <>
                    <Table columns={columns} data={votablePlayers} />
                </>}
                <Card.Footer>
                    <Button type="success" onClick={() => setConfirmVoteModal(true)}
                            disabled={voted || !votedOnEveryone}>
                        Submit Vote
                    </Button>
                </Card.Footer>
            </Card>
            <SubmitVoteConfirmationModal
                visible={confirmVoteModal}
                onClose={() => setConfirmVoteModal(false)}
                onSubmit={submitVote}
            />
        </>
    );
};

export default Voting;

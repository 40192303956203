// Import Firebase modules
import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, browserLocalPersistence, setPersistence, signInWithEmailAndPassword as signIn, signInAnonymously as firebaseSignInAnonymously } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyBz4MO3Vetd4g1c8vLCpcaVhHTI7xm5vW4",
    authDomain: "mapandterritory-ddf41.firebaseapp.com",
    projectId: "mapandterritory-ddf41",
    storageBucket: "mapandterritory-ddf41.appspot.com",
    messagingSenderId: "43166603245",
    appId: "1:43166603245:web:c320e8cb9b299d8e0779e4",
    measurementId: "G-5MLB5TTBTD",
};

// Initialize the Firebase application
const app = initializeApp(firebaseConfig);
console.log("Firebase initialized:", app);

// Initialize Firestore and other services
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const createInvitation = httpsCallable(functions, 'createInvitation');

try {
    setPersistence(auth, browserLocalPersistence)
        .catch((error) => {
            console.error('Error setting auth persistence:', error);
        });
} catch (error) {
    console.error('Error initializing Firebase:', error);
}

// Export functions and variables
export async function signInWithEmailAndPassword(email, password) {
    try {
        await signIn(auth, email, password);
    } catch (error) {
        console.error("Error signing in:", error);
        throw error;
    }
}

export async function signInAnonymously() {
    try {
        await firebaseSignInAnonymously(auth);
    } catch (error) {
        console.error("Error signing in anonymously:", error);
        throw error;
    }
}

export const registerWithEmailAndPassword = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendVerificationEmail(userCredential.user);
    } catch (error) {
        // Handle errors (e.g., weak password, email already in use)
    }
};

export const sendVerificationEmail = async (user) => {
    try {
        await sendEmailVerification(user);
    } catch (error) {
        // Handle errors (e.g., user not found)
    }
};

// Export other Firebase services
export { app as firebaseApp, db, auth, functions, createInvitation, serverTimestamp };

import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import axios from "axios";  // Import Axios
import { Button, Input, Checkbox, Spacer } from "@geist-ui/core";  // Import Checkbox component

const Register = () => {
    const [firstName, setFirstName] = useState("");  // State for first name
    const [lastName, setLastName] = useState("");    // State for last name
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newsletterOptIn, setNewsletterOptIn] = useState(false);  // State to manage checkbox
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();

    console.log("MailerLite API Key:", process.env.REACT_APP_MAILERLITE_API_KEY);


    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        try {
            setLoading(true);
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Store user information in Firestore
            const userDoc = doc(db, "users", user.uid);
            await setDoc(userDoc, {
                firstName: firstName,  // Save first name
                lastName: lastName,    // Save last name
                email: user.email,
                role: "teacher",
                hand: []
            });

            // Subscribe user to MailerLite newsletter if they opted in
            if (newsletterOptIn) {
                try {
                    await axios.post(
                        "https://server-for-mlt.onrender.com/api/subscribe",
                        {
                            email: user.email,
                            name: `${firstName} ${lastName}`  // Pass name to MailerLite
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-MailerLite-ApiKey": process.env.REACT_APP_MAILERLITE_API_KEY
                            }
                        }
                    );
                    console.log("User subscribed to newsletter:", user.email);
                } catch (axiosError) {
                    if (axiosError.response) {
                        // Server responded with a status other than 2xx
                        console.error("MailerLite API response error:", axiosError.response.data);
                        console.error("Response status:", axiosError.response.status);
                        console.error("Response headers:", axiosError.response.headers);
                    } else if (axiosError.request) {
                        // Request was made, but no response was received
                        console.error("MailerLite API request error: No response received", axiosError.request);
                    } else {
                        // Something else happened setting up the request
                        console.error("MailerLite API error:", axiosError.message);
                    }
                }
            }

            console.log("User registered:", user);
            navigate("/dashboard");
        } catch (error) {
            let errorMessage;
            switch (error.code) {
                case "auth/email-already-in-use":
                    errorMessage = "The email address is already in use by another account.";
                    break;
                case "auth/invalid-email":
                    errorMessage = "Invalid email format.";
                    break;
                case "auth/operation-not-allowed":
                    errorMessage = "Email/password accounts are not enabled.";
                    break;
                case "auth/weak-password":
                    errorMessage = "The password is too weak.";
                    break;
                default:
                    errorMessage = "Failed to register. Please try again.";
                    break;
            }
            console.error("Error registering user:", error);
            alert(errorMessage);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ textAlign: "center" }}>
            <h1>Register</h1>
            {error && <p>{error}</p>}

            {/* First Name Input */}
            <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} required>
                First Name
            </Input>
            <Spacer y={1} />

            {/* Last Name Input */}
            <Input value={lastName} onChange={(e) => setLastName(e.target.value)} required>
                Last Name
            </Input>
            <Spacer y={1} />

            {/* Email and Password Inputs */}
            <Input htmlType="email" value={email} onChange={(e) => setEmail(e.target.value)} required>Email</Input>
            <Spacer y={1} />
            <Input htmlType="password" value={password} onChange={(e) => setPassword(e.target.value)} required>Password</Input>
            <Spacer y={1} />
            <Input htmlType="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required>Confirm password</Input>
            <Spacer y={1} />

            {/* Newsletter Opt-In Checkbox */}
            <Checkbox checked={newsletterOptIn} onChange={(e) => setNewsletterOptIn(e.target.checked)}>
                Keep in touch about updates and news
            </Checkbox>
            <Spacer y={1} />

            <Button type="success" onClick={handleSubmit} loading={loading}>Register</Button>
        </div>
    );
};

export default Register;

import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useAuth } from "../UserContext";

const useDecks = () => {
    const { currentUser } = useAuth();
    const [decks, setDecks] = useState([]);

    const fetchDecks = async () => {
        const decksRef = collection(db, "decks");
        const q = query(decksRef, where("userId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);

        const fetchedDecks = [];
        querySnapshot.forEach((doc) => {
            fetchedDecks.push({ id: doc.id, ...doc.data() });
        });
        setDecks(fetchedDecks);
    };

    useEffect(() => {
        if (currentUser) {
            fetchDecks();
        }
    }, [currentUser]);


    return { decks, fetchDecks };
};

export default useDecks;
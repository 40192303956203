import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Profile from "./Profile";
import ResetPassword from "./ResetPassword";
import Dashboard from "./Dashboard";
import TerritoryCard from "./TerritoryCard";
import LegendCard from "./LegendCard";
import InkCard from "./InkCard";
import GameDeck from "./GameDeck";
import JoinGame from "./JoinGame";
import CardSelection from "./CardSelection";
import PlayerDashboard from "./PlayerDashboard";
import { auth, db } from "./firebase";
import Atlas from "./Atlas";
import Voting from "./Voting";
import Home from "./Home";
import EditDeck from "./decks/EditDeck";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import GameLobby from "./GameLobby";
import TeacherDashboard from "./TeacherDashboard";
import { HostUserProvider } from "./HostUserContext";
import { UserProvider, useAuth, UserContext } from "./UserContext";  // Updated import
import { Page } from "@geist-ui/core";


onAuthStateChanged(auth, async (user) => {
    if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userRef);

        if (!userSnapshot.exists()) {
            const role = user.email ? "teacher" : "player";

            await setDoc(userRef, {
                email: user.email,
                gameId: "",
                role: role,
                hand: []
            });
        }
    }
});

function RoutesWithAuth() {
    const currentUser = React.useContext(UserContext);
    const loadingUser = currentUser ? currentUser.loadingUser : false;

    if (loadingUser) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/game/:gameId/deck" element={<GameDeck />} />
                <Route path="/deck/:deckId/territory/create" element={<TerritoryCard />} />
                <Route path="/deck/:deckId/legend/create" element={<LegendCard />} />
                <Route path="/deck/:deckId/ink/create" element={<InkCard />} />
                <Route path="/join/:uniqueLink" element={<JoinGame />} />
                <Route path="/card-selection" element={<CardSelection />} />
                <Route path="/player-dashboard/:gameId/:playerId" element={<PlayerDashboard />} />
                <Route path="/routes" element={<Routes />} />
                <Route path="/game/:gameId/atlas" element={<Atlas />} />
                <Route path="/Voting" element={<Voting />} />
                <Route path="/" element={<Home />} />
                <Route path="/edit-deck/:deckId" element={<EditDeck />} />
                <Route path="/game/:gameId/:playerId" element={<GameLobby />} />
                <Route path="/teacher-dashboard/:gameId" element={<TeacherDashboard />} />
                <Route path="/lobby/:gameId" element={<GameLobby />} />
            </Routes>
        </Router>
    );
}

function App() {
    return (
        <UserProvider>
            <HostUserProvider> {/* Use HostUserProvider instead of HostUserContext.Provider */}
                <Page>
                    <RoutesWithAuth />
                </Page>
            </HostUserProvider>
        </UserProvider>
    );
}


export default App;

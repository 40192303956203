import React, { useState } from "react";
import { Input, Modal } from "@geist-ui/core";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

const AddDeckCardModal = ({ deckId, cardTypeName, visible, onClose }) => {
    const [name, setName] = useState("");

    const onSave = async () => {
        addDoc(collection(db, "decks", deckId, cardTypeName), {
            name
        });
        onClose();
        setName("");
    };

    const canSave = !!name;

    return (
        <Modal visible={visible} onClose={onClose} disableBackdropClick>
            <Modal.Title>Add card</Modal.Title>
            <Modal.Content>
                <Input value={name} onChange={e => setName(e.target.value)} autoFocus placeholder="Card name"
                       width="100%">Card
                    name</Input>
            </Modal.Content>
            <Modal.Action passive onClick={onClose}>Cancel</Modal.Action>
            <Modal.Action onClick={onSave} disabled={!canSave}>Save</Modal.Action>
        </Modal>
    );
};

export default AddDeckCardModal;

import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div>
            <h1>Maps, Legends, and Territories</h1>
            <p>
                <Link to="/login">Log in</Link> or <Link to="/register">Register</Link> to get started.
            </p>
        </div>
    );
};

export default Home;

import { Input, Modal, Slider, Spacer, Text } from "@geist-ui/core";
import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import GamePhase from "./GamePhase";
import { useNavigate } from "react-router-dom";
import useDeck from "../hooks/useDeck";
import useDecks from "../hooks/useDecks";
import Select from "../interface/Select";
import { useAuth } from "../UserContext";

const NewGameModal = ({ visible, onClose }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { decks } = useDecks();
    const [selectedDeck, setSelectedDeck] = useState("");
    const [numTeams, setNumTeams] = useState("2");
    const [unlockPlayers, setUnlockPlayers] = useState("2");
    const [name, setName] = useState("");
    const deck = useDeck(selectedDeck);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const onSave = async () => {
        const numTeamsInt = parseInt(numTeams);
        const newGameDoc = await addDoc(collection(db, "games"), {
            phase: GamePhase.PREPARATION,
            deckId: selectedDeck,
            numTeams: numTeamsInt,
            name,
            // unlockPercentage
            minimumPlayersForUnlock: parseInt(unlockPlayers),
            userId: currentUser.uid
        });

        Array(numTeamsInt).fill("").forEach(async () => await addDoc(collection(db, "games", newGameDoc.id, "players"), {
            name: "New Player",
            ready: false
        }));

        const possibilities = [];
        deck.territoryCards.forEach(territoryCard => {
            deck.legendCards.forEach(legendCard => {
                possibilities.push({
                    territoryCardId: territoryCard.id,
                    legendCardId: legendCard.id,
                    unlocked: false
                });
            });
        });

        await addDoc(collection(db, "atlas"), {
            gameId: newGameDoc.id,
            possibilities
        });

        navigate(`/teacher-dashboard/${newGameDoc.id}`);
    };

    const canSave = selectedDeck && name && unlockPlayers && numTeams;

    return (
        <Modal visible={visible} onClose={onClose} disableBackdropClick>
            <Modal.Title>Create new game</Modal.Title>
            <Modal.Content>
                <Input value={name} onChange={handleNameChange} placeholder="Game name" width="100%">Game name</Input>
                <Spacer h={.5} />
                <Text small>Deck</Text>
                <Select placeholder="Select a deck" value={selectedDeck} onChange={setSelectedDeck}
                        options={decks?.map(deck => ({ value: deck.id, label: deck.name }))}
                />
                <Spacer h={.5} />
                <Text small>Amount of teams</Text>
                <Select
                    placeholder="Amount of players"
                    value={numTeams}
                    onChange={setNumTeams}
                    options={[...Array(9)].map((_, i) => ({
                        value: (i + 2).toString(),
                        label: (i + 2).toString()
                    }))}
                />
                <Spacer h={.5} />
                <Text small>Minimum players to unlock atlas</Text>
                <Select placeholder="Minimum players to unlock atlas" value={unlockPlayers}
                        onChange={setUnlockPlayers}
                        options={[...Array(parseInt(numTeams - 1))].map((_, i) => ({
                            value: (i + 1).toString(),
                            label: (i + 1).toString()
                        }))}
                />
            </Modal.Content>
            <Modal.Action passive onClick={onClose}>Cancel</Modal.Action>
            <Modal.Action onClick={onSave} disabled={!canSave}>Save</Modal.Action>
        </Modal>
    );
};

export default NewGameModal;
import React from "react";
import { useAuth } from "./UserContext";
import ExistingGames from "./dashboard/ExistingGames";
import { Button, Spacer, Tabs, Text } from "@geist-ui/core";
import DecksOverview from "./dashboard/DecksOverview";
import Breadcrumbs from "./interface/Breadcrumbs";
import { getAuth } from "firebase/auth";


const Dashboard = () => {
    const { currentUser, loading } = useAuth();
    const auth = getAuth();

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!currentUser) {
        return <p>User not logged in.</p>;
    }

    return (
        <>
            <Breadcrumbs />
            <Tabs initialValue="games">
                <Tabs.Item label="Games" value="games">
                    <ExistingGames />
                </Tabs.Item>
                <Tabs.Item label="Decks" value="decks">
                    <DecksOverview />
                </Tabs.Item>
            </Tabs>
            <Spacer y={1} />
            <Button type="error" onClick={() => auth.signOut()}>Logout</Button>
        </>
    );
};

export default Dashboard;

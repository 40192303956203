import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth, signInAnonymously, serverTimestamp } from "./firebase";
import { collection, doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

const JoinGame = () => {
    const { id } = useParams(); // Fetch gameId directly from URL
    const navigate = useNavigate();

    useEffect(() => {
        const joinGame = async () => {
            const logMessage = async (message) => {
                const logRef = doc(collection(db, 'logs'), uuidv4());
                await setDoc(logRef, { message, timestamp: serverTimestamp() });
            };

            await logMessage('Game ID: ' + id);

            const userCredential = await signInAnonymously(auth);
            await logMessage('Signed in user: ' + userCredential.user.uid);

            const playerId = uuidv4();

            const playerRef = doc(collection(db, 'players'), playerId);
            await setDoc(playerRef, { gameId: id, userId: auth.currentUser.uid }); // Use id instead of gameId
            await logMessage('Player document created');

            let role = 'player'; // Default role is 'player'

            // If the user joins without a unique link, assign them the 'teacher' role
            if (!id) {
                role = 'teacher';
            }

            const newPlayer = {
                name: playerId,
                ready: false,
                territoryCards: [],
                legendCards: [],
                role: role
            };

            const gameLobbyRef = doc(db, 'gameLobbies', id);
            await updateDoc(gameLobbyRef, {
                players: arrayUnion(newPlayer)
            });
            await logMessage('Player object added to gameLobbies document');

            const gameRef = doc(db, 'games', id);
            const gameUpdateResult = await updateDoc(gameRef, { players: playerId });
            await logMessage('Game update result: ' + JSON.stringify(gameUpdateResult));
            await logMessage('Player id added to game document');

            await logMessage('Navigating to game lobby');
            navigate(`/game/${id}/lobby`);
        };

        joinGame();
    }, [id, navigate]);

    return (
        <div>
            <h1>Joining game...</h1>
        </div>
    );
};

export default JoinGame;

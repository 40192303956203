import React, { createContext, useState, useContext } from 'react';

const HostUserContext = createContext();

export const useHostUser = () => {
    const context = useContext(HostUserContext);
    if (!context) {
        throw new Error('useHostUser must be used within a HostUserProvider');
    }
    return context;
};

export const HostUserProvider = ({ children }) => {
    const [hostUserId, setHostUserId] = useState(null);

    const value = {
        hostUserId,
        setHostUserId,
    };

    return <HostUserContext.Provider value={value}>{children}</HostUserContext.Provider>;
};

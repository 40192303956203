import { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const usePlayer = (gameId, playerId) => {
    const [player, setPlayer] = useState();

    const getPlayer = async () => {
        const playerDoc = await getDoc(doc(db, "games", gameId, "players", playerId));
        setPlayer({ id: playerDoc.id, ...playerDoc.data() });
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "games", gameId, "players", playerId), (docSnapshot) => {
            setPlayer({ id: docSnapshot.id, ...docSnapshot.data() });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        getPlayer();
    }, [gameId, playerId]);

    return player;
};

export default usePlayer;
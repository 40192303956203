import { Select as CoreSelect } from "@geist-ui/core";

const Select = ({ placeholder, value, onChange, options }) => {
    return (
        <CoreSelect placeholder={placeholder} value={value} onChange={onChange} width="100%">
            {options?.map(option => <CoreSelect.Option
                    key={option.value}
                    value={option.value}>{option.label}
                </CoreSelect.Option>
            )}
        </CoreSelect>
    );
};

export default Select;
import React, { useState, useEffect } from "react";
import useGame from "./hooks/useGame";

const RoundTimer = ({ gameId }) => {
    const game = useGame(gameId);
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        if (timeRemaining === null && game?.roundStartedAt) {
            const secondsElapsed = Math.floor((new Date().getTime() - game.roundStartedAt.toDate().getTime()) / 1000);
            const timeRemaining = (game.initialTime * 60) - secondsElapsed;
            setTimeRemaining(timeRemaining > 0 ? timeRemaining : 0);
        }
        if (game?.forceRoundStop) {
            setTimeRemaining(0);
        }
    }, [game]);

    useEffect(() => {
        if (timeRemaining <= 0) {
            return;
        }

        const timer = setTimeout(() => {
            const secondsElapsed = Math.floor((new Date().getTime() - game.roundStartedAt.toDate().getTime()) / 1000);
            setTimeRemaining((game.initialTime * 60) - secondsElapsed);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeRemaining]);

    const minutesRemaining = Math.floor(timeRemaining / 60);
    const secondsRemaining = timeRemaining % 60;

    return (
        <div>
            <h2>Time remaining: {minutesRemaining}:{secondsRemaining < 10 ? "0" : ""}{secondsRemaining}</h2>
        </div>
    );
};

export default RoundTimer;


import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";

const useAtlas = (gameId) => {
    const [atlas, setAtlas] = useState(null);

    const fetchAtlas = async () => {
        const atlasRef = collection(db, "atlas");
        const q = query(atlasRef, where("gameId", "==", gameId));
        const querySnapshot = await getDocs(q);

        const fetchedAtlas = [];
        querySnapshot.forEach((doc) => {
            fetchedAtlas.push({ id: doc.id, ...doc.data() });
        });
        setAtlas(fetchedAtlas[0]);
    };

    useEffect(() => {
        if (!atlas) {
            return;
        }

        const unsubscribe = onSnapshot(doc(db, "atlas", atlas.id), (docSnapshot) => {
            setAtlas(prev => ({ ...prev, ...docSnapshot.data() }));
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (gameId) {
            fetchAtlas();
        }
    }, [gameId]);

    return { atlas, refetchAtlas: fetchAtlas };
};

export default useAtlas;
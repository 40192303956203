import { Modal } from "@geist-ui/core";
import React from "react";

const SubmitVoteConfirmationModal = ({visible, onClose, onSubmit}) => {
    return (
        <Modal visible={visible} onClose={onClose} disableBackdropClick>
            <Modal.Title>Confirm vote</Modal.Title>
            <Modal.Content>
                Are you sure you want to confirm your vote?
            </Modal.Content>
            <Modal.Action passive onClick={onClose}>Cancel</Modal.Action>
            <Modal.Action onClick={onSubmit}>Confirm</Modal.Action>
        </Modal>
    );
}

export default SubmitVoteConfirmationModal
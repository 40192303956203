import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAtlas from "./hooks/useAtlas";
import useGame from "./hooks/useGame";
import useDeck from "./hooks/useDeck";
import { Button, Card, Grid, Loading, Spacer, Text } from "@geist-ui/core";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useAuth } from "./UserContext";

const Atlas = () => {
    const { currentUser } = useAuth();
    const { gameId } = useParams();
    const navigate = useNavigate();
    const game = useGame(gameId);
    const deck = useDeck(game?.deckId);
    const { atlas, refetchAtlas } = useAtlas(gameId);

    const updateLock = async (possibility, unlocked) => {
        const possibilities = atlas.possibilities.map(p => {
            if (p.legendCardId === possibility.legendCardId && p.territoryCardId === possibility.territoryCardId) {
                return { ...p, unlocked };
            }
            return p;
        });

        // Need to update the entire collection because Firestore does not support updating a specific array element
        await updateDoc(doc(db, "atlas", atlas.id), { possibilities });
        refetchAtlas();
    };

    if (!atlas || !deck) {
        return <Loading />;
    }

    return (
        <div>
            <h2>Game Atlas</h2>
            <Button onClick={() => navigate(-1)}>Go back</Button>
            <Spacer y={1} />
            <div className="p-5">
                <Grid.Container gap={2} justify="center">
                    {atlas?.possibilities?.map(possibility => {
                        const territory = deck?.territoryCards?.find(card => card.id === possibility.territoryCardId);
                        const legend = deck?.legendCards?.find(card => card.id === possibility.legendCardId);
                        return (
                            <Grid xs="8" key={`${possibility.territoryCardId}:${possibility.legendCardId}`}>
                                <Card type={possibility.unlocked ? "success" : "warning"}
                                      style={{ opacity: possibility.unlocked ? 1 : 0.5 }}>
                                    <Text small>{territory?.name}</Text>
                                    <Spacer h={0.1} />
                                    <Text small>{legend?.name}</Text>
                                    {!currentUser?.isAnonymous &&
                                        (<Card.Footer>
                                            {possibility.unlocked ? <Button onClick={() => updateLock(possibility, false)}>Lock</Button> :
                                                <Button onClick={() => updateLock(possibility, true)}>Unlock</Button>}
                                        </Card.Footer>)
                                    }
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid.Container>
            </div>
        </div>
    );
};

export default Atlas;

import React, { useMemo } from "react";
import useGame from "../hooks/useGame";
import { calculateVoteResults } from "./utils";
import useDeck from "../hooks/useDeck";
import { Card } from "@geist-ui/core";

const VoteResults = ({ gameId }) => {
    const game = useGame(gameId);
    const deck = useDeck(game?.deckId);

    const correctVotes = useMemo(() => calculateVoteResults(game), [game]);

    if (!game || !deck || !correctVotes) {
        return <div>Loading...</div>;
    }

    return (
        <Card width="100%">
            <h4>Vote Results</h4>
            {Object.keys(correctVotes).map(voteId => {
                const player = game.players.find(p => p.id === voteId);
                const unlocked = correctVotes[voteId] >= game.minimumPlayersForUnlock;
                const legendCard = deck.legendCards.find(card => card.id === player.selectedLegend);
                const territoryCard = deck.territoryCards.find(card => card.id === player.selectedTerritory);
                return <div key={voteId}>{player.name} got {correctVotes[voteId]} correct votes
                    - {unlocked ? `Unlocked ${territoryCard.name} x ${legendCard.name}` : `Nothing unlocked`}</div>;
            })}
        </Card>
    );
};
export default VoteResults;

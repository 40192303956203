import React, { useState } from "react";
import { db } from "./firebase";

const TerritoryCard = ({ deckId, card, editMode }) => {
    const [name, setName] = useState(editMode ? card.name : "");
    const [description, setDescription] = useState(editMode ? card.description : "");
    const [image, setImage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (editMode) {
                // Update the existing card in editMode
                await db
                    .collection("decks")
                    .doc(card.deckId)
                    .collection("territoryCards")
                    .doc(card.id)
                    .update({ name, description });
                alert("Territory card updated successfully.");
            } else {
                // Create a new card
                await db
                    .collection("decks")
                    .doc(deckId)
                    .collection("territoryCards")
                    .add({ name, description });
                alert("Territory card created successfully.");
            }
        } catch (error) {
            console.error("Error creating/updating territory card:", error);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </label>
            <label>
                Description:
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
            </label>
            <label>
                Image URL:
                <input type="url" value={image} onChange={(e) => setImage(e.target.value)} />
            </label>
            <button type="submit">{editMode ? "Update Territory Card" : "Create Territory Card"}</button>
        </form>
    );
};

export default TerritoryCard;

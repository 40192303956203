import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    doc,
    deleteDoc
} from "firebase/firestore";
import useDeck from "../hooks/useDeck";
import { Button, Card, Grid, Loading, Spacer } from "@geist-ui/core";
import Breadcrumbs from "../interface/Breadcrumbs";
import { PlusCircle, Trash } from "@geist-ui/icons";
import AddDeckCardModal from "./AddDeckCardModal";

const DeckCardTitle = ({ children, onClick }) => (
    <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }}>
        <h4 style={{ margin: 0 }}>{children}</h4>
        <PlusCircle onClick={onClick} style={{ cursor: "pointer" }} />
    </div>
);

const DeckCardList = ({ cards, onDelete }) => (
    <ul>
        {cards.map((card) => (
            <div key={card.id} style={{ display: "flex", justifyContent: "space-between" }}>
                <li>{card.name}</li>
                <Trash color="red" onClick={() => onDelete(card.id)} />
            </div>
        ))}
    </ul>
);


const EditDeck = () => {
    const { deckId } = useParams();
    const deck = useDeck(deckId);
    const [inkCards, setInkCards] = useState([]);
    const [legendCards, setLegendCards] = useState([]);
    const [territoryCards, setTerritoryCards] = useState([]);
    const [addCardModal, setAddCardModal] = useState("");

    useEffect(() => {
        const inkCardsQuery = query(collection(db, "decks", deckId, "inkCards"), orderBy("name"));
        const legendCardsQuery = query(collection(db, "decks", deckId, "legendCards"), orderBy("name"));
        const territoryCardsQuery = query(collection(db, "decks", deckId, "territoryCards"), orderBy("name"));

        const inkCardsUnsubscribe = onSnapshot(inkCardsQuery, (querySnapshot) => {
            const inkCards = [];
            querySnapshot.forEach((doc) => {
                inkCards.push({ id: doc.id, ...doc.data() });
            });
            setInkCards(inkCards);
        });

        const legendCardsUnsubscribe = onSnapshot(legendCardsQuery, (querySnapshot) => {
            const legendCards = [];
            querySnapshot.forEach((doc) => {
                legendCards.push({ id: doc.id, ...doc.data() });
            });
            setLegendCards(legendCards);
        });

        const territoryCardsUnsubscribe = onSnapshot(territoryCardsQuery, (querySnapshot) => {
            const territoryCards = [];
            querySnapshot.forEach((doc) => {
                territoryCards.push({ id: doc.id, ...doc.data() });
            });
            setTerritoryCards(territoryCards);
        });

        const unsubscribe = () => {
            inkCardsUnsubscribe();
            legendCardsUnsubscribe();
            territoryCardsUnsubscribe();
        };

        return () => {
            unsubscribe();
        };
    }, [deckId]);

    const handleDeleteCard = async (cardType, cardId) => {
        try {
            await deleteDoc(doc(db, "decks", deckId, cardType, cardId));
            console.log(`${cardType} card deleted successfully.`);
        } catch (error) {
            console.error(`Error deleting ${cardType} card:`, error);
        }
    };

    if (!deck) {
        return <Loading />;
    }

    const breadcrumbItems = [
        { label: "Decks" },
        { label: `Edit deck ${deck.name}` }
    ];

    return (
        <div>
            <Breadcrumbs items={breadcrumbItems} />
            <Spacer y={2} />
            <Grid.Container gap={1} justify="space-around">
                <Grid md={6} lg={12}>
                    <Card width="100%">
                        <DeckCardTitle onClick={() => setAddCardModal("territoryCards")}>Territory Cards</DeckCardTitle>
                        <Spacer y={1} />
                        <DeckCardList cards={territoryCards}
                                      onDelete={(cardId) => handleDeleteCard("territoryCards", cardId)} />
                    </Card>
                </Grid>
                <Grid md={6} lg={12}>
                    <Card width="100%">
                        <DeckCardTitle onClick={() => setAddCardModal("legendCards")}>Legend Cards</DeckCardTitle>
                        <DeckCardList cards={legendCards}
                                      onDelete={(cardId) => handleDeleteCard("legendCards", cardId)} />
                    </Card>
                </Grid>
                <Grid md={6} lg={12}>
                    <Card width="100%">
                        <DeckCardTitle onClick={() => setAddCardModal("inkCards")}>Ink Cards</DeckCardTitle>
                        <DeckCardList cards={inkCards}
                                      onDelete={(cardId) => handleDeleteCard("inkCards", cardId)} />
                    </Card>
                </Grid>
            </Grid.Container>
            <AddDeckCardModal visible={addCardModal} deckId={deckId} cardTypeName={addCardModal}
                              onClose={() => setAddCardModal("")} />
        </div>
    );
};

export default EditDeck;



import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Button, ButtonGroup, Input, useToasts } from "@geist-ui/core";
import dealCards from "../dealCards";
import Table from "../interface/Table";
import { CheckInCircleFill, X } from "@geist-ui/icons";
import RedealCardsConfirmationModal from "./RedealCardsConfirmationModal";

const PlayerLinks = ({ game }) => {
    const [confirmModalPlayerId, setConfirmModalPlayerId] = useState("");
    const { setToast } = useToasts();

    if (!game?.players) {
        return <div>Loading...</div>;
    }

    const copyToClipboard = (playerId) => {
        navigator.clipboard.writeText(`${window.location.origin}/game/${game.id}/${playerId}`);
    };

    const handlePlayerNameChange = async (name, playerId) => {
        await updateDoc(doc(db, "games", game.id, "players", playerId), { name });
    };

    const handleRedealCards = async (playerId) => {
        const {
            territoryCards, legendCards
        } = await dealCards({ deckId: game.deckId, territoryCardsAmount: 3, legendCardsAmount: 2 });
        await updateDoc(doc(db, "games", game.id, "players", playerId), {
            selectedTerritory: null,
            selectedLegend: null,
            votes: null,
            legendCards,
            territoryCards
        });
        setConfirmModalPlayerId("");
        setToast({ text: "Cards redealt for player", type: "success", delay: 2000 });
    };

    const columns = [
        {
            id: "name",
            label: "Name",
            render: (name, player) => <Input
                value={name}
                onChange={(event) => handlePlayerNameChange(event.target.value, player.id)}
                placeholder="Enter player name"
                onFocus={(e) => e.target.select()}
            />,
            width: 200
        },
        {
            id: "ready",
            label: "ready",
            render: (ready) => ready ? <CheckInCircleFill /> : <X color="red" />
        },
        {
            id: "selectedTerritory",
            label: "selected cards",
            render: (id, player) => player.selectedTerritory && player.selectedLegend ? <CheckInCircleFill /> :
                <X color="red" />
        },
        {
            id: "votes",
            label: "votes",
            render: (votes) => votes ? <CheckInCircleFill /> : <X color="red" />
        },
        {
            id: "id",
            label: "Actions",
            render: (playerId) => <ButtonGroup>
                <Button onClick={() => copyToClipboard(playerId)}>Copy invite link</Button>
                <Button onClick={() => setConfirmModalPlayerId(playerId)}>Redeal cards</Button>
            </ButtonGroup>,
            width: 150
        }
    ];

    return (
        <>
            <RedealCardsConfirmationModal
                playerId={confirmModalPlayerId}
                onClose={() => setConfirmModalPlayerId("")}
                onSave={handleRedealCards}
            />
            <Table data={game.players} columns={columns} />
        </>
    );
};
export default PlayerLinks;

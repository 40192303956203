import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore"; // Add this import

const GameDeck = () => {
    const { gameId } = useParams();
    const [game, setGame] = useState(null);

    useEffect(() => {
        const fetchGame = async () => {
            try {
                const gameDoc = await getDoc(doc(db, "games", gameId)); // Update this line
                if (gameDoc.exists()) {
                    setGame({ id: gameDoc.id, ...gameDoc.data() });
                } else {
                    console.error("No game found with the provided ID.");
                }
            } catch (error) {
                console.error("Error fetching game:", error);
            }
        };

        fetchGame();
    }, [gameId]);

    if (!game) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{game.name}</h1>
            <p>{game.description}</p>
            <div>
                <h2>Game Deck</h2>
                <Link to={`/game/${gameId}/deck/territory`}>Create Territory Card</Link>
                <br />
                <Link to={`/game/${gameId}/deck/legend`}>Create Legend Card</Link>
                <br />
                <Link to={`/game/${gameId}/deck/ink`}>Create Ink Card</Link>
            </div>
        </div>
    );
};

export default GameDeck;

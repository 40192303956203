import React from "react";
import useDeck from "../hooks/useDeck";
import Select from "../interface/Select";

const TerritorySelect = ({ deckId, value, onChange }) => {
    const deck = useDeck(deckId);

    return (
        <Select
            placeholder="Select a territory card"
            value={value}
            onChange={onChange}
            options={deck?.territoryCards?.map(card => ({ value: card.id, label: card.name }))}
        />
    );
};
export default TerritorySelect;

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, signInAnonymously } from "./firebase";
import { db } from "./firebase";
import useGame from "./hooks/useGame";
import usePlayer from "./hooks/usePlayer";
import GamePhase from "./game/GamePhase";
import { Button, Display } from "@geist-ui/core";

const GameLobbyWrapper = () => {
    const signInAnonymouslyIfNotLoggedIn = async () => {
        await signInAnonymously();
    };

    useEffect(() => {
        if (!auth.currentUser) {
            signInAnonymouslyIfNotLoggedIn();
        }
    }, [auth]);


    if (!auth.currentUser) {
        return <div>Loading...</div>;
    }

    return <GameLobby />;
};

const GameLobby = () => {
    const { gameId, playerId } = useParams();
    const game = useGame(gameId);
    const player = usePlayer(gameId, playerId);
    const navigate = useNavigate();

    const joinLobby = async () => {
        try {
            await updateDoc(doc(db, "games", gameId, "players", playerId), { uid: auth.currentUser.uid, ready: true });
        } catch (error) {
            console.error("Error joining game lobby:", error);
        }
    };

    // Ensure player does not stay on this page while game already started
    useEffect(() => {
        if (!player) return;

        const unsubscribe = onSnapshot(doc(db, "games", gameId), (docSnapshot) => {
            const gameData = docSnapshot.data();
            if (gameData && gameData.phase !== GamePhase.PREPARATION) {
                navigate(`/player-dashboard/${gameId}/${playerId}`);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [player]);

    if (!game || !player) {
        return <div>Loading...</div>;
    }

    return (
        <Display style={{ textAlign: "center" }}>
            <h1>Game Lobby</h1>
            <p>
                {player.ready ? <b>Please wait for the game to start</b> :
                    <Button type="success" onClick={joinLobby}>Join Lobby</Button>}
            </p>
        </Display>
    );
};

export default GameLobbyWrapper;

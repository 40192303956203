import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

const UserContext = React.createContext();

export const useAuth = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true); // Initial value is true

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);

            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userSnapshot = await getDoc(userRef);

                if (!userSnapshot.exists()) {
                    // User document does not exist, create a new one
                    // Check if the user signed up with an email or was invited via a link
                    const role = user.email ? "teacher" : "player";

                    await setDoc(userRef, {
                        email: user.email,
                        gameId: "",
                        role: role,
                        hand: [],
                    });
                }
            }

            setLoadingUser(false); // User has finished loading here
        });

        return unsubscribe;
    }, []);

    const value = { currentUser, loadingUser }; // Make sure to include loadingUser here

    return (
        <UserContext.Provider value={value}>
            {!loadingUser && children}
        </UserContext.Provider>
    );
};

export { UserContext };  // Export UserContext

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { collection, doc, addDoc } from "firebase/firestore";

const InkCard = () => {
    const { deckId } = useParams();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    // ... other state variables

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await addDoc(collection(db, "decks", deckId, "inkCards"), {
                name,
                description,
                // other card data
            });
            // ... handle successful card creation
        } catch (error) {
            console.error("Error creating ink card:", error);
        }
    };

    return (
        <div>
            <h1>Create Ink Card</h1>
            <form onSubmit={handleSubmit}>
                {/* form inputs and labels */}
                <button type="submit">Create Ink Card</button>
            </form>
        </div>
    );
};

export default InkCard;

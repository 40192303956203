import React from "react";
import { Modal } from "@geist-ui/core";

const RedealCardsConfirmationModal = ({ playerId, onClose, onSave }) => {
    return (
        <Modal visible={playerId} onClose={onClose} disableBackdropClick>
            <Modal.Title>Redeal cards</Modal.Title>
            <Modal.Content>
                Redeal cards for player?
            </Modal.Content>
            <Modal.Action passive onClick={onClose}>Cancel</Modal.Action>
            <Modal.Action onClick={() => onSave(playerId)}>Confirm</Modal.Action>
        </Modal>
    );
};

export default RedealCardsConfirmationModal;
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GeistProvider, CssBaseline } from "@geist-ui/core";
import "./style.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <GeistProvider>
            <CssBaseline />
            <App />
        </GeistProvider>
    </React.StrictMode>
);

reportWebVitals();

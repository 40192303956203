import React, { useState } from "react";
import Card from "./Card";

const CardSelection = (
    {
        territoryCards,
        legendCards,
        selectedTerritory,
        selectedLegend,
        onSelectTerritory,
        onSelectLegend
    }
) => {
    return (
        <div>
            <h4>Territory Cards</h4>
            <div className="territory-cards">
                {territoryCards?.map((card, i) => (
                    <Card
                        key={`${card.id}_${i}`}
                        card={card}
                        selected={selectedTerritory === `${card.id}_${i}`}
                        onClick={() => onSelectTerritory(`${card.id}_${i}`)}
                    />
                ))}
            </div>

            <h4>Legend Cards</h4>
            <div className="legend-cards">
                {legendCards?.map((card, i) => (
                    <Card
                        key={`${card.id}_${i}`}
                        card={card}
                        selected={selectedLegend === `${card.id}_${i}`}
                        onClick={() => onSelectLegend(`${card.id}_${i}`)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CardSelection;

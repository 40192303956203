import React, { useState } from "react";
import { auth } from "./firebase";
import { Button, Display, Input, Spacer } from "@geist-ui/core";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    console.log("ResetPassword component rendered");
    console.log("Current email state:", email);


    const handleSubmit = async () => {
        console.log("Submit button clicked with email:", email);
        try {
            await sendPasswordResetEmail(email);
            console.log("Password reset email sent successfully");
            setMessage("Password reset email sent. Please check your inbox.");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            setMessage("Error sending password reset email.");
        }
    };

    const sendPasswordResetEmail = async (email) => {
        try {
            await auth.sendPasswordResetEmail(email);
            return { success: true };
        } catch (error) {
            return { success: false, error };
        }
    };

    return (
        <Display style={{ textAlign: "center" }}>
            <h1>Reset Password</h1>
            <Input
                htmlType="email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    console.log("Email input changed to:", e.target.value);
                }} placeholder="Email"
                   required />
            <Spacer y={0.5} />
            <Button onClick={handleSubmit}>Reset Password</Button>
            {message && <p>{message}</p>}
        </Display>
    );
};

export default ResetPassword;

import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../UserContext";

const useGames = () => {
    const { currentUser } = useAuth();
    const [data, setData] = useState();

    const fetchGames = async () => {
        const gamesRef = collection(db, "games");
        const q = query(gamesRef, where("userId", "==", currentUser.uid));
        const gamesSnapshot = await getDocs(q);
        const gamesData = gamesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setData(gamesData);
    };

    useEffect(() => {
        if (!data) {
            fetchGames();
        }
    }, []);

    return { data, refetch: fetchGames };
};

export default useGames;
import React, { useState, useEffect } from "react";
import {
    doc,
    updateDoc
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import CardSelection from "./CardSelection";
import RoundTimer from "./RoundTimer";
import Voting from "./Voting";
import { db } from "./firebase";
import useGame from "./hooks/useGame";
import usePlayer from "./hooks/usePlayer";
import GamePhase from "./game/GamePhase";
import VoteResults from "./game/VoteResults";
import { Button, Card, Spacer } from "@geist-ui/core";

const PlayerDashboard = () => {
    const navigate = useNavigate();
    const { gameId, playerId } = useParams();
    const game = useGame(gameId);
    const player = usePlayer(gameId, playerId);
    const [selectedTerritory, setSelectedTerritory] = useState(null);
    const [selectedLegend, setSelectedLegend] = useState(null);

    useEffect(() => {
        if (player) {
            setSelectedTerritory(player?.selectedTerritory);
            setSelectedLegend(player?.selectedLegend);
        }
    }, [player]);

    const handleConfirmSelection = async (selectedTerritory, selectedLegend) => {
        try {
            // Remove trailing underscore and number, only used to prevent rendering issues in UI
            const sanitizedTerritory = selectedTerritory.split("_")[0];
            const sanitizedLegend = selectedLegend.split("_")[0];
            const playerDoc = doc(db, "games", gameId, "players", playerId);
            await updateDoc(playerDoc, { selectedTerritory: sanitizedTerritory, selectedLegend: sanitizedLegend });
        } catch (error) {
            console.error("Error updating game phase and selected cards:", error);
        }
    };

    if (!game?.phase) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Player Dashboard</h1>
            <h4>Welcome {player?.name}</h4>
            {game.remainingTime !== null && game.phase === GamePhase.ROUND_TIMER &&
                <RoundTimer gameId={gameId} />}
            <Card width="100%">
                <h4>Game Configuration</h4>
                Ink card: {game.selectedInkCard?.name || "None"}
                <Card.Footer>
                    <Button onClick={() => navigate(`/game/${gameId}/atlas`)}>View Atlas</Button>
                </Card.Footer>
            </Card>
            <Spacer y={1} />
            {game.phase === GamePhase.CARD_SELECTION && <Card width="100%">
                <h2>Card selection</h2>
                <>
                    {(!player.selectedLegend || !player.selectedTerritory) && <CardSelection
                        territoryCards={player.territoryCards}
                        legendCards={player.legendCards}
                        selectedTerritory={player.selectedTerritory || selectedTerritory}
                        selectedLegend={player.selectedLegend || selectedLegend}
                        onSelectTerritory={setSelectedTerritory}
                        onSelectLegend={setSelectedLegend}
                    />}
                    <Card.Actions>
                        {player.selectedLegend && player.selectedTerritory ?
                            <p>Selection confirmed, please wait for the game to start</p> :
                            <Button type="success"
                                    onClick={() => handleConfirmSelection(selectedTerritory, selectedLegend)}>Confirm
                                Selection
                            </Button>}
                    </Card.Actions>
                </>
            </Card>}
            {game.phase === GamePhase.VOTING && <Voting gameId={gameId} playerId={playerId} />}
            {game.phase === GamePhase.VOTE_RESULTS && <VoteResults gameId={gameId} />}
            {game.phase === GamePhase.ENDED && <h2>Game Ended!</h2>}
        </div>
    );
};

export default PlayerDashboard;

import React, { useState } from "react";
import { Input, Modal } from "@geist-ui/core";
import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../UserContext";
import { useNavigate } from "react-router-dom";


const CreateDeckModal = ({ visible, onClose }) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [name, setName] = useState("");

    const onSave = async () => {
        try {
            const deckRef = await addDoc(collection(db, "decks"), {
                name: name,
                userId: currentUser.uid
            });

            const deckId = deckRef.id;

            collection(doc(db, "decks", deckId), "inkCards");
            collection(doc(db, "decks", deckId), "legendCards");
            collection(doc(db, "decks", deckId), "territoryCards");


            navigate(`/edit-deck/${deckId}`);
        } catch (error) {
            console.error("Error creating deck:", error);
        }
    };

    const canSave = !!name;

    return (
        <Modal visible={visible} onClose={onClose} disableBackdropClick>
            <Modal.Title>Create deck</Modal.Title>
            <Modal.Content>
                <Input value={name} onChange={e => setName(e.target.value)} placeholder="Deck name" width="100%">Deck
                    name</Input>
            </Modal.Content>
            <Modal.Action passive onClick={onClose}>Cancel</Modal.Action>
            <Modal.Action onClick={onSave} disabled={!canSave}>Save</Modal.Action>
        </Modal>
    );
};

export default CreateDeckModal;
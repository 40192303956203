export const calculateVoteResults = (game) => {
    let correctVotes = game?.players?.reduce((acc, curr) => ({ ...acc, [curr.id]: 0 }), {});
    game?.players?.forEach(player => {
        player.votes.forEach(vote => {
            const votedPlayer = game.players.find(p => p.id === vote.playerId);
            if (votedPlayer.selectedTerritory === vote.territory && votedPlayer.selectedLegend === vote.legend) {
                correctVotes[votedPlayer.id] += 1;
            }
        });
    });

    return correctVotes;
}